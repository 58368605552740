import * as React from "react";
import { graphql } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import styled from "styled-components";
import SEO from "../components/SEO";

const TermsOfService = ({ data }) => {
  const { tos } = data.sanitySiteSettings;
  return (
    <>
      <SEO
        title="CLIPS Hair Studio | Terms of Service"
        description="Please read carefully before booking an appointment with our salon."
        url="https://www.clips-hairstudio.com/terms-of-service/"
      />
      <TosContent>
        <BlockContent blocks={tos} />
      </TosContent>
    </>
  );
};

export default TermsOfService;

const TosContent = styled.div`
  position: relative;
  top: var(--nav-height);
  max-width: 960px;
  padding: 2rem;
  margin: 0 auto;
  p {
    max-width: none;
    margin: 0 auto;
  }
`;

export const query = graphql`
  query MyQuery {
    sanitySiteSettings(tos: { elemMatch: { _key: { eq: "0063d6b9cef1" } } }) {
      id
      tos {
        _key
        _type
        style
        children {
          _key
          _type
          marks
          text
        }
      }
    }
  }
`;
